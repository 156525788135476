<template>
  <div class="
      min-h-screen
      flex
      items-center
      justify-center
      bg-gray-50
      py-12
      px-4
      sm:px-6
      lg:px-8
    ">
    <div :class="isLoading ? 'hidden' : 'block'" class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="../../assets/mystidia.png" alt="Mystidia" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Récupérer votre compte
        </h2>
      </div>
      <form class="mt-8 space-y-6" @submit.prevent="forget">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Adresse Email</label>
            <input v-model="form.email" id="email-address" name="email" type="email" autocomplete="email"
              required="required" class="
                appearance-none
                relative
                block
                w-full
                px-3
                py-3
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-md
                focus:outline-none
                focus:ring-red-400
                focus:border-red-400
                focus:z-10
                sm:text-sm
              " placeholder="Adresse Email" />
          </div>
        </div>

        <div>
          <button type="submit" class="
              group
              relative
              w-full
              flex
              justify-center
              py-3
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-red-400
              hover:bg-red-500
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-red-400
            ">
            Récupérer
          </button>
        </div>
      </form>
    </div>
    <div :class="isLoading ? 'block' : 'hidden'">
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
    <!--<div
      :class="isLoading ? 'block' : 'hidden'"
      class="fulfilling-square-spinner"
    >
      <div class="spinner-inner"></div>
    </div>-->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        email: "",
      },
      error: null,
      isLoading: false,
    };
  },

  mounted() { },

  methods: {
    forget() {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "/sendPasswordResetLink", this.form)
        .then(({ data }) => {
          this.$router.push("/");
        })
        .catch((error) => {
          this.error = error.response.data.errors;
        });
    },
  },
};
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>